import {
  AfterViewChecked,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TraceabilityStateService } from '../services/state-service/traceability-state.service';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { ScreenEnum } from '../enums/screens.enum';
import { MapPageComponent } from '../map-page/map-page.component';
import { PrimengExportsModule } from '../primeng-exports.module';
import { UtilityService } from '../services/utility.service';
import { PageHeaderComponent } from '../shared/page-header/page-header.component';
import { UserInfoStateService } from '../services/state-service/user-info-state.service';
import { SettingsStateService } from '../services/state-service/settings-state.service';

@Component({
  selector: 'app-traceability-shell',
  standalone: true,
  imports: [
    CommonModule,
    DashboardComponent,
    MapPageComponent,
    PrimengExportsModule,
    PageHeaderComponent
  ],
  templateUrl: './traceability-shell.component.html',
  styleUrls: ['./traceability-shell.component.scss'],
})
export class TraceabilityShellComponent implements OnInit, AfterViewChecked {
  @ViewChild('container', { static: false }) container!: ElementRef;
  screens = ScreenEnum;
  get canAssignToken() {
    return (
      this.authService.userInfoToken === 'null' ||
      !this.authService.userInfoToken ||
      this.route.snapshot.params['token']
    );
  }

  get canAssignFirebaseToken() {
    return (
      this.authService.firebaseToken === 'null' ||
      !this.authService.firebaseToken ||
      this.route.snapshot.queryParams['ftoken']
    );
  }

  constructor(
    public traceabilityStateService: TraceabilityStateService,
    private userInfoStateService: UserInfoStateService,
    private settingsStateService: SettingsStateService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private utilityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.initializeGlobal();
  }

  ngAfterViewChecked() {
    this.emitContentHeight();
  }

  initializeGlobal() {
    if (this.route.snapshot.params['token']) {
      this.initializeToken();
      this.initializeSettings();
      this.initializeUserInfo();
    }
    if (this.route.snapshot.queryParams['ftoken']) {
      this.initializeFirebaseToken();
    }
  }

  initializeUserInfo() {
    const defaultMode = this.route.snapshot.queryParams['defaultMode'];
    this.userInfoStateService.getUserInfo(defaultMode);
  }

  initializeSettings() {
    this.settingsStateService.getSettings();
  }

  emitContentHeight() {
    const divHeight = this.container.nativeElement.offsetHeight;
    const remValue = divHeight / 14;
    this.utilityService.emitPropToParent('contentHeight', remValue);
  }

  initializeToken() {
    if (this.canAssignToken) {
      this.authService.userInfoToken =
        this.route.snapshot.params['token'] || null;
    }
  }

  initializeFirebaseToken() {
    if (this.canAssignFirebaseToken) {
      this.authService.firebaseToken =
        this.route.snapshot.queryParams['ftoken'] || null;
      this.utilityService.signInWithCustomToken(this.route.snapshot.queryParams['ftoken'] || null);
    }
  }
}
