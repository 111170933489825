import { Injectable } from '@angular/core';
import csvDownload from 'json-to-csv-export';
import { TraceabilityData } from '../models/traceability-state.model';
import { DashboardService } from './data-service/dashboard.service';
import { UtilityService } from './utility.service';
import * as saveAs from 'file-saver';
import { EventStateService } from './state-service/event-state.service';
import { DownloadOptionsEnum } from '../enums/download-options.enum';
import JSZip from 'jszip';
import { catchError, filter, interval, mergeMap, Observable, Subject, switchMap, take, takeWhile, tap, throwError } from 'rxjs';
import { DdrDownloadV2 } from '../models/ddr-download.model';
import { SI_NUMBER_TABLE_COLUMNS } from '../constants/table-columns.const';
import { TranslocoService } from '@jsverse/transloco';
import { ReportPayload } from '../models/report-payload.model';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class DownloadService {
  filesToZip$ = new Subject<{ file: Blob | null; fileName: string }>();
  filesToZip: { file: Blob | null; fileName: string }[] = [];
  zipEndpointResultLength = 0;

  constructor(
    private dashboardService: DashboardService,
    private utilityService: UtilityService,
    private eventStateService: EventStateService,
    private translocoService: TranslocoService,
    private datePipe: DatePipe,
  ) {
    this.filesToZip$.subscribe((files) => {
      this.filesToZip.push(files);
      if (this.filesToZip.length === 3 + this.zipEndpointResultLength) {
        const zip = new JSZip();
        this.filesToZip.forEach((file) => {
          if (file.file) {
            zip.file(file.fileName, file.file);
          }
        });

        zip.generateAsync({ type: 'blob' }).then((content: Blob) => {
          saveAs(content, 'EUDRPackage.zip');
        });
      }
    });
  }

  downloadSiInformation(rowData: TraceabilityData[]) {
    const data = rowData.map((data) => ({
      'SI Number': data.si_number || '-',
      Risks: data.risks || '-',
      'Polygon Data': data.has_plantation_data || '-',
      'EUDR Package': data.has_eudr_package || '-',
      'DDR': data.has_ddr || '-',
      'Contract Number': data.contract_number || '-',
      'Reference Number': data.buyer_ref_numb || '-',
      'PO Number': data.po_number || '-',
      Counterparty: data.counterparty || '-',
      'Destination': data.destination,
      Symbol: data.factory_code || '-',
      Grade: data.grade_name || '-',
      Volume: data.si_weight || '-',
      'Delivery Month': data.delivery_datetime || '-',
    }));

    const dataToConvert = {
      data: data,
      filename: this.translocoService.translate(`DASHBOARD.SI_INFORMATION_FILENAME`),
      delimiter: ',',
      headers: SI_NUMBER_TABLE_COLUMNS.map((column) => this.translocoService.translate(column.displayName)),
    };

    csvDownload(dataToConvert);
  }

  download(downloadOption: DownloadOptionsEnum, rowData: TraceabilityData[]) {
    const siList = this.utilityService.getSiListArray(rowData);
    const siDetailList = this.utilityService.getSiDetailListArray(rowData);

    this.dashboardService.download(downloadOption, siList, siDetailList).subscribe({
      next: (res) => {
        const fileName = this.utilityService.getFileNameFromHeaders(res);

        saveAs(res.body as Blob, fileName);
        switch (downloadOption) {
          case DownloadOptionsEnum.POLYGON_DATA_EUIS:
            this.eventStateService.downloadEuisLoading = false;
            break;
          case DownloadOptionsEnum.COMBINED_POLYGON_DATA_EUIS:
              this.eventStateService.downloadCombinedEuisLoading = false;
              break;
          case DownloadOptionsEnum.FULL_RUSK_REPORT:
            this.eventStateService.downloadFullRiskLoading = false;
            break;
          case DownloadOptionsEnum.EUDR_PACKAGE:
            this.eventStateService.downloadEudrPackageLoading = false;
            break;
          default:
            break;
        }
      },
    });
  }

  downloadDdrV2(payload: DdrDownloadV2) {
    this.dashboardService.downloadDdrV2(payload).subscribe((res) => {
      saveAs(res.url, 'Due Diligence Report');
      this.eventStateService.isDownloadDdrLoading = false;
      this.eventStateService.isDdrDownloaded = true;
      this.utilityService.emitToast({
        isSuccess: true,
        message: 'Due diligence report downloaded successfully.',
      });
    });
  }

  submitAndDownloadReport(payload: ReportPayload): Observable<void> {
    return this.dashboardService.submitReport(payload).pipe(
      take(1), // Ensure only one submission happens
      switchMap((res) => {
        if (res?.task_id) {
          return this.pollDownloadReport(res.task_id).pipe(
            filter((downloadRes) => downloadRes.status === 'Success'), // Wait for status 'Success' and a valid signed_url
            take(1), // Take the first successful response
            tap((downloadRes) => {
              const fileName = `DOP_${this.datePipe.transform(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;
              saveAs(downloadRes.signed_url, fileName); // Trigger the file download
            })
          );
        } else {
          return throwError(() => new Error('No task_id received from submitReport'));
        }
      }),
      catchError((err) => {
        console.error('Error in submitAndDownloadReport:', err);
        return throwError(() => err);
      })
    );
  }
   
  pollDownloadReport(taskId: string) {
    return interval(5000).pipe( // Trigger every 5 seconds
      switchMap(() => this.dashboardService.downloadReport(taskId)),
      mergeMap((downloadRes) => {
        if (downloadRes.status === 'Failed') {
          // Throw an error if status is 'Failed'
          return throwError(() => new Error('Report generation failed.'));
        }
        return [downloadRes]; // Emit the response if not failed
      }),
      takeWhile(
        (downloadRes) => downloadRes.status !== 'Success', // Continue until status is 'Success'
        true // Emit the final successful value before completion
      )
    );
  }
}
