import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { PrimengExportsModule } from '../primeng-exports.module';
import { FormsModule } from '@angular/forms';
import { RefreshButtonComponent } from '../shared/refresh-button/refresh-button.component';
import { TraceabilityTableComponent } from './traceability-table/traceability-table.component';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { TraceabilityStateService } from '../services/state-service/traceability-state.service';
import { debounceTime, filter, skip, Subject, takeUntil, tap } from 'rxjs';
import { UserInfoStateService } from '../services/state-service/user-info-state.service';
import { EventStateService } from '../services/state-service/event-state.service';
@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    RefreshButtonComponent,
    TraceabilityTableComponent,
    TranslocoPipe,
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  periodOptions = [
    {
      label: this.translateLastNMonths(3),
      value: this.getPeriodValue(3),
    },
    {
      label: this.translateLastNMonths(6),
      value: this.getPeriodValue(6),
    },
    {
      label: this.translateLastNMonths(12),
      value: this.getPeriodValue(12),
    },
  ];
  selectedPeriod: string = this.getPeriodValue(12)!;
  lastUpdatedDate = '30/06/2023';
  relaunchTable$ = new Subject<boolean>();

  private destroyed$ = new Subject<void>();

  constructor(
    private datePipe: DatePipe,
    private translocoService: TranslocoService,
    private traceabilityStateService: TraceabilityStateService,
    public userInfoStateService: UserInfoStateService,
    public eventStateService: EventStateService
  ) {}

  ngOnInit(): void {
    this.setScreenTitle();
    this.intializeRelaunchTable();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private setScreenTitle() {
    this.traceabilityStateService.setTitle('DASHBOARD.MY_TRACEABILITY');
  }

  private intializeRelaunchTable() {
    // Relaunch table when tabs changed so that component values are reset
    // Only on trader company type
    this.userInfoStateService.viewAsSetting$
      .pipe(
        filter((setting => !!setting)),
        skip(1), // first load
        tap(() => this.relaunchTable$.next(true)),
        debounceTime(500),
        takeUntil(this.destroyed$)
      ).subscribe(() => {
        this.selectedPeriod = this.getPeriodValue(12)!;
        this.relaunchTable$.next(false);
      });
  }

  getPeriodValue(lastNMonths: number) {
    const today = new Date();
    const month = new Date(today).setMonth(today.getMonth() - lastNMonths);

    return this.datePipe.transform(month, 'yyyy-MM-dd');
  }

  onSelectedPeriodChanged(period: string) {
    this.selectedPeriod = period;
  }

  translateLastNMonths(value: number) {
    return this.translocoService.translate('DASHBOARD.LAST_N_MONTHS', {
      value: value,
    });
  }
}
