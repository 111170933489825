import { Injectable } from '@angular/core';
import { TraceabilityData } from 'src/app/models/traceability-state.model';
import { StateService } from './state.service';
import { CreateNewSiAction, INITIAL_TRADER_STATE, TraderState } from 'src/app/models/trader-state.model';
import { TraderLinkScreenEnum } from 'src/app/enums/screens.enum';
import { CreateNewSiOptionsEnum } from 'src/app/enums/create-new-options.enum';

@Injectable({ providedIn: 'root' })
export class TraderStateService extends StateService<TraderState> {
  createNewSiAction$ = this.select(({ createNewSiAction }) => createNewSiAction)
  selectedNewSi$ = this.select(({ selectedNewSi }) => selectedNewSi);
  
  buyer = () => this.state.buyer;
  selectedScreen = () => this.state.selectedScreen;
  showCreateSiModal = () => this.state.showCreateSiModal;
  selectedCreateNewSiOption = () => this.state.selectedCreateNewSiOption;
  createNewSiAction = () => this.state.createNewSiAction;
  disableCreateSi = () => this.state.disableCreateSi;
  
  constructor() {
    super(INITIAL_TRADER_STATE);
  }

  setBuyer(data: TraceabilityData[]) {
    this.setState({ buyer: data })
  }

  setSelectedScreen(selected: TraderLinkScreenEnum) {
    this.setState({ selectedScreen: selected })
  }

  setCreateNewSiAction(action?: CreateNewSiAction) {
    this.setState({ createNewSiAction: action })
  }

  setSelectedCreateNewSiOption(option?: CreateNewSiOptionsEnum) {
    this.setState({ selectedCreateNewSiOption: option })
  }

  setSelectedNewSi(si?: TraceabilityData | null) {
    this.setState({ selectedNewSi: si })
  }

  setShowCreateSiModal(show?: boolean) {
    this.setState({ showCreateSiModal: show });
  }

  setCreateSiDisabled(disable?: boolean) {
    this.setState({ disableCreateSi: disable });
  }

  reset() {
    this.resetState(INITIAL_TRADER_STATE);
  }
}
