import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CustomStepperMenuItem } from './models/custom-stepper.model';

@Component({
  selector: 'app-custom-stepper',
  templateUrl: './custom-stepper.component.html',
  standalone: true,
  styleUrls: ['./custom-stepper.component.scss'],
  imports: [CommonModule],
})
export class CustomStepperComponent {
  @Input() activeIndex: number = 0;
  @Input() menu: CustomStepperMenuItem[] = [];
}
