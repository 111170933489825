import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service'; // Import the service
import { Subject, takeUntil } from 'rxjs';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UtilityService } from 'src/app/services/utility.service';
import { ColumnDefinition } from 'src/app/models/column-definition.model';
import { PLANTATION_LIST_DOP_COLUMNS } from 'src/app/constants/table-columns.const';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { Table } from 'primeng/table';
import { DateOfProductionData } from 'src/app/models/report-payload.model';
import { DownloadService } from 'src/app/services/download.service';

@Component({
  selector: 'app-generate-dop-modal',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoPipe
  ],
  templateUrl: './generate-dop-modal.component.html',
  styleUrls: ['./generate-dop-modal.component.scss']
})
export class GenerateDopModalComponent implements OnInit {
  today: Date = new Date();
  plantations: any = [];
  totalRecords: number = 0;
  tableDataKey = 'plantation_code';
  pageSize = 10;
  isLoading: boolean = false;
  currentPage: number = 0;
  currentFirst: number = 0;
  rows: number = 10;
  destroyed$ = new Subject<void>();
  columns: ColumnDefinition[] = PLANTATION_LIST_DOP_COLUMNS;
  selectedPlantations: any[] = [];
  selectedInPageItems: any[] = [];
  summaryPlantations: any[] = [];
  allDateRange: Date | undefined;
  tableLoading: boolean = true;
  finalPage = false;
  dopForm!: FormGroup;
  selectAllChecked: boolean = false;

  @ViewChild('dopTable') dopTable!: Table;

  constructor(
    private traceabilityStateService: TraceabilityStateService,
    private dialogData: DynamicDialogConfig,
    private utilityService: UtilityService,
    public eventStateService: EventStateService,
    private dialogRef: DynamicDialogRef,
    private translocoService: TranslocoService,
    private downloadService: DownloadService
  ) {}

  ngOnInit(): void {
    this.dopForm = new FormGroup({
      includeInEudr: new FormControl(true)
    });
    this.subscribeToLoadingState();
    this.initializeSelectedTraceabilityData();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  
  subscribeToLoadingState(): void {
    this.eventStateService.isPlantationBySiLoaded$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(loaded => {
        this.tableLoading = !loaded
      });
  }

  search(event: any){
    this.dopTable.filterGlobal(event.target.value, 'contains');
  }

  initializeSelectedTraceabilityData() {
    const siDetailIds = this.utilityService.getSiDetailListArray(this.dialogData.data);
    const siNumbers = this.utilityService.getSiListArray(this.dialogData.data);
    this.traceabilityStateService.getPlantationsBySi(siNumbers, siDetailIds)
    this.traceabilityStateService.plantationsBySi$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(plantations => {
        if(plantations) {
          this.plantations = plantations;
        }
      });
  }

  closeModal(){
    this.dialogRef.close();
  }
  
  nextPage(){
    this.currentFirst = 0;
    this.finalPage = true;

    this.summaryPlantations = this.plantations.filter((plantation: any) => plantation?.production_dates !== null && plantation?.production_dates !== undefined);
  }

  back() {
    this.currentFirst = 0;
    this.finalPage = false;
  }

  generateDop() {
    this.isLoading = true;
  
    const plantationsDopData = this.summaryPlantations.map(plantation => ({
      plantation_code: plantation.plantation_code,
      plantation_name: plantation.plantation_name,
      date_of_production: this.formatDateRange(plantation.production_dates)
    }));
  
    const data: DateOfProductionData = {
      sidetail_id: this.dialogData.data[0].sidetail_id,
      include_eudr_package: this.dopForm.get('includeInEudr')?.value ?? false,
      plantations_dop: plantationsDopData
    };
  
    const payload = this.utilityService.constructReportPayload('date-of-production', data);
  
    this.downloadService.submitAndDownloadReport(payload).subscribe({
      next: () => {
        if(this.dopForm.get('includeInEudr')?.value && !this.dialogData.data[0].has_eudr_package) {
          const payload = {
            si: this.dialogData.data[0].si_number,
            sidetail_id: this.dialogData.data[0].sidetail_id
          };
          this.traceabilityStateService.setEudrFiles(payload);
        }
        this.dialogRef.close();
        this.isLoading = false;
        this.selectedPlantations = [];
        this.summaryPlantations = [];
      },
      error: (error) => {
        console.error('Download failed', error);
        this.isLoading = false;
      }
    });
  }
  
  formatDateRange(dates: (string | null)[]): string {
    const format = (dateStr: string) => {
      const date = new Date(dateStr);
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    };
  
    if (dates.length >= 2 && dates[1] !== null) {
      return `${format(dates[0] as string)} - ${format(dates[1] as string)}`;
    } else if (dates[1] === null && dates[0] !== null)  {
      return `${format(dates[0] as string)} - ${format(dates[0] as string)}`;
    }
    return '';
  }

  private getSelections() {
    const start = this.currentPage * this.pageSize;
    const end = start + this.pageSize;
    const currentPageItems = this.plantations.slice(start, end);

    this.selectedInPageItems = this.selectedPlantations.filter(item => currentPageItems.includes(item));

    this.selectAllChecked = this.selectedPlantations.length === this.plantations.length;
  }

  enableNext() {
    return this.plantations.length > 0 &&
     this.plantations.some((plantation: any) => plantation?.production_dates !== null && plantation?.production_dates !== undefined);
  }

  addDatesToAll() {

    this.getSelections();

    if (!this.allDateRange) {
      return;
    }
  
    if (this.selectAllChecked) {
      const hasExistingDates = this.plantations.some((plantation: any) => plantation.production_dates);
  
      if (hasExistingDates) {
        this.selectedInPageItems.forEach(plantation => {
          plantation.production_dates = this.allDateRange;
        });
      } else {
        this.plantations.forEach((plantation: any) => {
          plantation.production_dates = this.allDateRange;
        });
      }
    } else {
      this.selectedInPageItems.forEach(plantation => {
        plantation.production_dates = this.allDateRange;
      });
    }
  }

  translateSubheader(key: string) {
      return this.translocoService.translate(key, {
        trn: this.dialogData.data[0].si_number,
      });
  }

  toggleSelectAll(event: any) {
    this.selectAllChecked = event.checked
    if (this.selectAllChecked) {
      this.selectedPlantations = [...this.plantations];
    } else {
      this.selectedPlantations = [];
    }
  }

  onSelectionChange() {
    this.getSelections();
  }

  onPageChange(event: any) {
    this.currentPage = event.first / this.pageSize;
    this.onSelectionChange();
  }
}