import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  Map,
  FullscreenControl,
  NavigationControl,
  Popup,
  LngLatLike,
  LngLatBounds,
  Marker
} from 'mapbox-gl';
import { ProgressBarSkeletonComponent } from 'src/app/shared/progress-bar-skeleton/progress-bar-skeleton.component';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { Subject, takeUntil, skip } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LayersMapControlComponent } from '../layers-map-control/layers-map-control.component';
import {
  PlantationsBySi,
  RiskLayer,
  TraceabilityData
} from 'src/app/models/traceability-state.model';
import { BBox, bbox } from '@turf/turf';
import { GeoJsonTypesEnum } from 'src/app/enums/geojson-types.enum';
import { GeoJSONGeometryOrNull } from 'wellknown';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [
    CommonModule,
    ProgressBarSkeletonComponent,
    LayersMapControlComponent
  ],
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit, OnDestroy {
  @ViewChild('map') mapElement!: ElementRef;
  map!: Map;
  lat = 0;
  long = 0;
  isInitialLoad = true;
  mapMarkersSet = new Set<Marker>();
  hoverPopupPlantation: Popup | null = null;

  destroyed$ = new Subject<void>();

  constructor(
    public traceabilityStateService: TraceabilityStateService,
    private eventStateService: EventStateService,
    private uitlityService: UtilityService
  ) {}

  ngOnInit(): void {
    this.initializeMap();
    this.listenToZoomPlantation();
    this.listenToHoverPlantation();
  }

  ngOnDestroy(): void {
    this.traceabilityStateService.setMapStyle('');
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  initializeMap() {
    this.traceabilityStateService.getMapStyle();
    this.traceabilityStateService.mapStyle$
      .pipe(takeUntil(this.destroyed$), skip(1))
      .subscribe((mapStyle) => {
        this.map = new Map({
          accessToken: environment.mapboxToken,
          container: 'map',
          style: mapStyle,
          renderWorldCopies: false,
          // whole earth zoom
          zoom: 1,
          center: [this.lat, this.long]
        });

        this.map.addControl(new FullscreenControl(), 'top-right');
        this.map.addControl(new NavigationControl(), 'top-right');

        this.map.on('load', () => {
          this.loadRiskSourcesAndLayers();
          this.loadPlantationSourcesAndLayers();
        });
      });
  }

  showPropsOnPolygonHover(siNumbers: string[]) {
    const layers = siNumbers.flatMap((id) => ['circle_' + id, 'fill_' + id]);
    let popup: Popup | null = null;
    this.map.on('mouseenter', layers, (e) => {
      this.map.getCanvas().style.cursor = 'pointer';
      const plantation = {
        coordinates: (e.features![0].geometry as any).coordinates,
        type: e.features![0].layer.type,
        label: e.features![0]!.properties!['description']!
      };

      popup = this.getPopup(plantation.label);
      popup
        .setLngLat(
          this.findCenterCoordinate(plantation.coordinates, plantation.type)
        )
        .addTo(this.map);
    });

    this.map.on('mouseleave', layers, () => {
      this.map.getCanvas().style.cursor = '';
      popup?.remove();
    });
  }

  showPropsOnPlantationNameHover(plantation: PlantationsBySi | null) {
    if (plantation) {
      const geoData = plantation._geo as any;
      this.hoverPopupPlantation = this.getPopup(
        plantation.plantation_name || '-'
      );
      this.hoverPopupPlantation
        .setLngLat(this.findCenterCoordinate(geoData.coordinates, geoData.type))
        .addTo(this.map);
      return;
    }
    this.hoverPopupPlantation!.remove();
  }

  loadPlantationSourcesAndLayers() {
    this.traceabilityStateService.checkedSelectedTraceabilityData$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((traceabilityData) => {
        if (traceabilityData.length) {
          const layers = this.getLayers<TraceabilityData>(traceabilityData);
          this.removePlantationSourcesAndLayers(layers.uncheckedLayers);
          this.addPlantationSourcesAndLayers(layers.checkedLayers);
        }
      });
  }

  addPlantationSourcesAndLayers(traceabilityData: TraceabilityData[]) {
    const boundingBoxValues: BBox[] = [];
    const plantations = [];
    if (traceabilityData.length) {
      traceabilityData.forEach((data) => {
        const features: any[] = [];
        data.plantationData?.forEach((plantation) => {
          plantations.push(plantation);
          const plantationGeoData = plantation?._geo as GeoJSONGeometryOrNull;
          if (plantationGeoData) {
            const geoData: any = plantationGeoData;
            const featureObject = {
              type: 'Feature',
              properties: {
                description: plantation.plantation_name || '-'
              },
              geometry: {
                type: geoData.type,
                coordinates: geoData.coordinates
              }
            };
            features.push(featureObject);

            // TODO: remove when confirmed with PO
            // this.showPlantationMarkers(plantation, geoData);

            boundingBoxValues.push(bbox(geoData));
          }
        });

        this.eventStateService.isPlantationBySiLoaded = true;

        if (features.length && !this.map.getSource(data.si_number!)) {
          const featureCollection = {
            id: data.si_number,
            data: {
              type: 'FeatureCollection',
              features: features
            } as any
          };
          this.map.addSource(featureCollection.id!, {
            type: 'geojson',
            data: featureCollection.data
          });
          this.map.addLayer({
            id: `fill_${data.si_number!}`,
            type: 'fill',
            source: data.si_number!,
            paint: {
              'fill-color': '#89A9FC'
            },
            layout: {
              visibility: 'visible'
            },
            filter: ['==', '$type', 'Polygon']
          });

          this.map.addLayer({
            id: `circle_${data.si_number!}`,
            type: 'circle',
            source: data.si_number!,
            paint: {
              'circle-radius': 6,
              'circle-color': '#89A9FC'
            },
            layout: {
              visibility: 'visible'
            },
            filter: ['==', '$type', 'Point']
          });
        } else {
          this.toggleLayoutVisibility([data], true);
        }
      });

      this.showPropsOnPolygonHover([
        ...traceabilityData.map((data) => data.si_number!)
      ]);

      if (boundingBoxValues.length && this.isInitialLoad) {
        this.zoomToFitBounds(boundingBoxValues);
      }

      this.isInitialLoad = false;
    }
  }

  removePlantationSourcesAndLayers(traceabilityData: TraceabilityData[]) {
    this.toggleLayoutVisibility(traceabilityData, false);
  }

  toggleLayoutVisibility(traceabilityData: TraceabilityData[], show: boolean) {
    if (traceabilityData.length) {
      traceabilityData.forEach((data) => {
        const fillLayerId = this.map.getLayer(`fill_${data.si_number!}`)?.id;
        const circleLayerId = this.map.getLayer(
          `circle_${data.si_number!}`
        )?.id;
        if (fillLayerId) {
          this.map.setLayoutProperty(
            fillLayerId,
            'visibility',
            show ? 'visible' : 'none'
          );
        }

        if (circleLayerId) {
          this.map.setLayoutProperty(
            circleLayerId,
            'visibility',
            show ? 'visible' : 'none'
          );
        }

        //   // TODO: remove when confirmed with PO
        //   data.plantationData?.forEach((plantation) => {
        //     // this.hidePlantationMarkers(plantation);
        //   });
        // });
      });
    }
  }

  // TODO: remove when confirmed with PO
  showPlantationMarkers(plantation: PlantationsBySi, feature: any) {
    const centerCoordinates = this.findCenterCoordinate(
      feature.coordinates,
      feature.type
    );
    this.mapMarkersSet.add(
      new Marker().setLngLat(centerCoordinates).addTo(this.map)
    );
  }

  hidePlantationMarkers(plantation: PlantationsBySi) {
    const mapMarkers = Array.from(this.mapMarkersSet);
    const geoData = plantation._geo as GeoJSONGeometryOrNull;
    const plantationCoordinates = this.findCenterCoordinate(
      (plantation._geo as any)!.coordinates,
      geoData!.type
    );

    mapMarkers.forEach((marker) => {
      const markerCoordinates = [
        marker.getLngLat().lng,
        marker.getLngLat().lat
      ];

      if (
        this.uitlityService.areArraysEqual(
          plantationCoordinates as number[],
          markerCoordinates
        )
      ) {
        marker.remove();
      }
    });
  }

  findCenterCoordinate(coordinates: number[][][], type: string): LngLatLike {
    if (type === GeoJsonTypesEnum.POINT || type === 'circle') {
      return coordinates as any;
    }
    const numPoints = coordinates[0].length;
    const avgLatitude =
      coordinates[0]?.reduce((sum, point) => sum + point[1], 0) / numPoints;
    const avgLongitude =
      coordinates[0]?.reduce((sum, point) => sum + point[0], 0) / numPoints;
    return [avgLongitude, avgLatitude];
  }

  getPopup(text: string) {
    return new Popup({
      closeButton: false,
      closeOnClick: false
    }).setHTML(text);
  }

  zoomToFitBounds(boundingBoxValues: any[]) {
    const bounds = new LngLatBounds();
    boundingBoxValues.forEach((boundingBox) => {
      bounds.extend(boundingBox);
    });

    this.map.fitBounds(bounds, { padding: 40 });
  }

  listenToHoverPlantation() {
    this.eventStateService.hoveredPlantation$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((plantation) => {
        this.showPropsOnPlantationNameHover(plantation);
      });
  }

  listenToZoomPlantation() {
    this.eventStateService.zoomPlantation$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((plantations) => {
        if (plantations.length) {
          const bounds = new LngLatBounds();
          plantations.forEach((plantation) => {
            bounds.extend(bbox(plantation._geo) as any);
          });
          if (
            plantations.length === 1 &&
            (plantations[0]?._geo as any)?.type === GeoJsonTypesEnum.POINT
          ) {
            this.map.fitBounds(bounds, { padding: 80, maxZoom: 15 });
          } else {
            this.map.fitBounds(bounds, { padding: 80 });
          }
        }
      });
  }

  loadRiskSourcesAndLayers() {
    this.traceabilityStateService.riskLayers$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((riskLayers) => {
        if (riskLayers.length) {
          const layers = this.getLayers<RiskLayer>(riskLayers);

          this.removeRiskRoucesAndLayers(layers.uncheckedLayers);
          this.addRiskSourcesAndLayers(layers.checkedLayers);
        }
      });
  }

  getLayers<T>(layers: T[]) {
    const checkedLayers = [...layers].filter((layer: any) => layer.checked);
    const uncheckedLayers = [...layers].filter((layer: any) => !layer.checked);

    return {
      checkedLayers: checkedLayers,
      uncheckedLayers: uncheckedLayers
    };
  }

  removeRiskRoucesAndLayers(layers: RiskLayer[]) {
    layers.forEach((layer) => {
      if (this.map.getSource(layer.id)) {
        this.map.removeLayer(layer.id);
        this.map.removeSource(layer.id);
      }
    });
  }

  addRiskSourcesAndLayers(layers: RiskLayer[]) {
    layers.forEach((layer) => {
      if (!this.map.getSource(layer.id)) {
        this.map.addSource(layer.id, {
          type: layer.type as any,
          tiles: [layer.dataurl]
        });
        this.map.addLayer({
          id: layer.id,
          type: layer.type as any,
          source: layer.id,
          minzoom: 0,
          maxzoom: 22
        });
      }
    });
  }
}
