<div
  *ngIf="!(eventStateService.isTraderLinkingTRN$ | async)"
  class="pb-0 text-lg font-semibold flex justify-content-between align-items-center"
>
  <span> {{ "DASHBOARD.SUMMARY" | transloco }} </span>
  <div class="flex align-item-center period-dropdown mb-2">
    <span class="p-inputgroup-addon">
      <i class="pi pi-calendar calendar-icon"></i>
    </span>
    <p-dropdown
      [options]="periodOptions"
      [ngModel]="selectedPeriod"
      (ngModelChange)="onSelectedPeriodChanged($event)"
      optionLabel="label"
      optionValue="value"
    ></p-dropdown>
  </div>
</div>
<!-- <div class="flex">
  <app-refresh-button [lastUpdatedDate]="lastUpdatedDate"></app-refresh-button>
</div> -->
<!-- <div class="grid my-3 summary-cards">
  <div class="col-6">
    <p-card> </p-card>
  </div>
  <div class="col-6">
    <p-card> </p-card>
  </div>
</div> -->
<div class="grid my-3">
  <div class="col-12">
    <app-traceability-table 
      *ngIf="(userInfoStateService.userInfo$ | async) && !(relaunchTable$ | async)" 
      [period]="selectedPeriod">
    </app-traceability-table>
  </div>
</div>
