import { Injectable } from '@angular/core';
import { DashboardService } from '../data-service/dashboard.service';
import { StateService } from './state.service';
import {
  INITIAL_PAGED_DATA_STATE,
  INITIAL_TRACEABILITY_STATE,
  RiskLayer,
  TraceabilityData,
  TraceabilityState,
  TradeConfirmationData
} from 'src/app/models/traceability-state.model';
import { ScreenEnum } from 'src/app/enums/screens.enum';
import { MapPageService } from '../data-service/map-page.service';
import { PlantationListPayload } from 'src/app/models/plantation-list-payload.model';
import { TransactionsPagePayload } from 'src/app/models/transactions-page-payload.model';
import { combineLatest, concatMap, filter, map, Observable, of, take } from 'rxjs';
import { CompanyTypesEnum } from 'src/app/enums/company-types.enum';
import { PlantationRisksPayload } from 'src/app/models/plantation-risks-payload.model';
import { TableParams } from 'src/app/models/table-params.model';
import { GeometryPayload } from 'src/app/models/geometry-payload.model';
import { TraceabilityTableTabsEnum } from 'src/app/enums/traceability-table-tabs.enum';

@Injectable({
  providedIn: 'root',
})
export class TraceabilityStateService extends StateService<TraceabilityState> {
  pagedTraceabilityData$ = this.select((state) => state.pagedTraceabilityData);
  pagedCounterpartiesData$ = this.select(
    (state) => state.pagedCounterpartiesData
  );
  currentScreen$ = this.select((state) => state.currentScreen);
  pagedTradeConfirmationData$ = this.select(
    (state) => state.pagedTradeConfirmationData
  );
  siDetails$ = this.select((state) => state.siDetails);
  pagedLinkPlantationData$ = this.select(
    (state) => state.pagedLinkPlantationData
  );
  siPageTableFilters$ = this.select((state) => state.siPageFilters);
  mapStyle$ = this.select((state) => state.mapStyle);
  selectedTraceabilityData$ = this.select(
    (state) => state.selectedTraceabilityData
  );
  checkedSelectedTraceabilityData$ = this.select(
    (state) => state.checkedSelectedTraceabilityData
  );
  plantationRisks$ = this.select((state) => state.plantationRisks);
  plantationDatasets$ = this.select((state) => state.plantationDatasets);
  transactionDatasets$ = this.select((state) => state.transactionDatasets);
  riskLayers$ = this.select((state) => state.riskLayers);
  plantationsBySi$ = this.select((state) => state.plantationsBySi);
  compositionData$ = this.select((state) => state.compositionData);
  siSummaries$ = this.select((state) => state.siSummaries);
  plantationGeometryData$ = this.select(
    (state) => state.plantationGeometryData
  );
  symbolOptions$ = this.select((state) => state.symbolOptions);
  gradeOptions$ = this.select((state) => state.gradeOptions);
  geometryData$ = this.select((state) => state.geometryData);
  plantationListFilters$ = this.select((state) => state.plantationListFilters);
  title$ = this.select((state) => state.title);

  constructor(
    private dashboardService: DashboardService,
    private mapPageService: MapPageService
  ) {
    super(INITIAL_TRACEABILITY_STATE);
  }

  getPlantationListFilters(plantationParams: PlantationListPayload) {
    if (!plantationParams.period) return;

    combineLatest([
      this.dashboardService.getPlantationListFilters(plantationParams),
      this.plantationRisks$,
    ]).subscribe(([filters, riskProperties]) => {
      if (filters && riskProperties) {
        const filtersWithRisks = {
          risks: riskProperties?.map((risk) => ({
            label: risk.display_name,
            value: { [`${risk.name}_value`]: true },
            iconColor: risk.color,
          })),
        };

        filtersWithRisks.risks?.unshift({
          label: 'No Risks',
          value: { has_any_risk_value: false },
          iconColor: '',
        });
        this.setState({
          plantationListFilters: { ...filters, ...filtersWithRisks } as any,
        });
      }
    });
  }

  getCountryPage() {
    this.dashboardService.getCountryPage().subscribe((res) => {
      this.setState({ pagedTraceabilityData: res });
    });
  }

  getSiPage(userViewAs$: Observable<CompanyTypesEnum | null>, params: any) {
    userViewAs$
      .pipe(
        take(1),
        concatMap(userViewAs => {
          if (userViewAs) {
            return this.dashboardService.getSiPage(params).pipe(
              map((res) => {
                if (userViewAs) {
                  const modifiedResults = res.results?.map((item) => ({
                    ...item,
                    counterparty:
                      userViewAs === CompanyTypesEnum.Producer
                        ? item.contract_buyer_nm
                        : item.producer,
                  })) as TraceabilityData[];
                  return { ...res, results: modifiedResults };
                }

                return null;
              })
            );
          }
          return of(null);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.setState({ pagedTraceabilityData: res });
        }
      });
  }

  getSiPageFilters(params: { [param: string]: string | number | boolean } | null) {
    this.dashboardService.getSiPageFilters(params).subscribe((res) => {
      this.setState({ siPageFilters: res });
    });
  }

  getTradeConfirmationPage(userViewAs$: Observable<CompanyTypesEnum | null>, params: any) {
    userViewAs$
      .pipe(
        concatMap((userViewAs) =>
          this.dashboardService.getTradeConfirmationPage(params).pipe(
            map((res) => {
              if (userViewAs) {
                const modifiedResults = res.results?.map((item) => ({
                  ...item,
                  counterparty:
                    userViewAs === CompanyTypesEnum.Producer
                      ? item.consumer.name
                      : item.producer.name,
                })) as TradeConfirmationData[];
                return { ...res, results: modifiedResults };
              }

              return null;
            })
          )
        )
      )
      .subscribe((res) => {
        if (res) {
          this.setState({ pagedTradeConfirmationData: res });
        }
      });
  }

  getCounterparties(params: any) {
    return this.dashboardService
    .getCounterparties(params)
      .subscribe((counterparties) => {
      this.setState({ pagedCounterpartiesData: counterparties });
    });
  }

  getPlantationGeometryData(
    plantationCodes: string[],
    crd: string,
    plantationTable: string
  ) {
    this.dashboardService
      .getPlantationGeometryData(plantationCodes, crd, plantationTable)
      .subscribe((res) => {
        this.setState({ plantationGeometryData: res });
      });
  }

  getTransactionPage(payload: TransactionsPagePayload) {
    return this.dashboardService
      .getTransactionPage(payload)
      .subscribe((res) => {
        this.setState({ pagedLinkPlantationData: res });
      });
  }

  getSiDetails(siNumber: string) {
    this.dashboardService.getSiDetails(siNumber).subscribe((res) => {
      this.setState({ siDetails: res });
    });
  }

  getPlantationDatasets() {
    this.dashboardService
      .getPlantationDatasets()
      .subscribe((res) => this.setState({ plantationDatasets: res }));
  }

  getTransactionDatasets(factoryCode: string) {
    this.dashboardService
      .getTransactionDatasets(factoryCode)
      .subscribe((res) => this.setState({ transactionDatasets: res }));
  }

  getMapStyle() {
    this.mapPageService
      .getMapStyle()
      .subscribe((mapStyle) => this.setState({ mapStyle: mapStyle }));
  }

  getRiskLayers() {
    return this.mapPageService.getRiskLayers().subscribe((riskLayers) => {
      this.setState({ riskLayers: riskLayers });
    });
  }

  getPlantationsBySi(siList: string[], siDetailIdList: number[],withRisk = true) {
    return this.mapPageService
      .getPlantationsBySi(siList, siDetailIdList, withRisk)
      .subscribe((res) => {
        this.setState({ plantationsBySi: res });
      });
  }

  getCompositionData(siList: string[]) {
    return this.mapPageService.getCompositionData(siList).subscribe((res) => {
      this.setState({ compositionData: res });
    });
  }

  getSiSummaries(siList: string[], siDetailList: number[]) {
    return this.dashboardService.getSiSummaries(siList, siDetailList).subscribe((res) => {
      this.setState({ siSummaries: res });
    });
  }

  getPlantationRisks(payload: PlantationRisksPayload) {
    this.dashboardService
      .getPlantationRiskProperties(payload)
      .subscribe((res) => {
        this.setState({ plantationRisks: res });
      });
  }

  getGeometryData2(
    params: GeometryPayload,
    plantationParams: PlantationListPayload
  ) {
    this.dashboardService
      .getGeometryData2(params, plantationParams)
      .subscribe((res) => {
        this.setState({ geometryData: res });
      });
  }

  getPlantationsPaginated(
    tableParams: TableParams,
    plantationParams: PlantationListPayload
  ) {
    this.plantationRisks$
      .pipe(
        filter((plantationRisks) => !!plantationRisks),
        take(1),
        concatMap((plantationRisks) => {
          if (!plantationRisks) return of(null);
          return this.dashboardService
            .getPlantationsPaginated(
              plantationParams,
              tableParams,
              plantationRisks
            )
            .pipe(
              map((plantations) => {
                plantations.results?.forEach((plantation: any) => {
                  plantationRisks.forEach((prop) => {
                    const riskBoolean = `${prop.name}_value`;
                    if (plantation[riskBoolean]) {
                      plantation.risks?.push(prop);
                    }
                  });
                });

                return plantations;
              })
            );
        })
      )
      .subscribe((plantations) => {
        if (plantations) {
          this.setState({ pagedLinkPlantationData: plantations });
        }
      });
  }

  getCounterpartyOptions(companyType: CompanyTypesEnum | null, id: number) {
    this.dashboardService
      .getCounterpartyOptions(companyType, id)
      .subscribe((res: any) => {
        this.setState({
          symbolOptions: res.factories,
          gradeOptions: res.grades,
        });
      });
  }

  getEudrFiles(siNumber: string, siDetailId: number): Observable<File[]> {
    return this.dashboardService.getEudrFiles(siNumber, siDetailId).pipe(
      map((res: any) => {
        if (res.length > 0) {
          this.setState({ 
            eudrFiles: res,
          });
        }
        return res;
      })
    );
  }

  setEudrFiles(payload: any) {
    this.dashboardService.setEudrFiles(payload).subscribe();
  }

  clearSiDetails() {
    this.setState({ siDetails: null });
  }

  // TODO: refactor
  clearPagedTraceabilityData() {
    this.setState({ pagedTraceabilityData: INITIAL_PAGED_DATA_STATE });
  }

  clearCreateNewSiTableData() {
    this.setState({ pagedTradeConfirmationData: INITIAL_PAGED_DATA_STATE });
    this.setState({ pagedCounterpartiesData: INITIAL_PAGED_DATA_STATE });
  }

  clearPagedLinkedPlantationData() {
    this.setState({ pagedLinkPlantationData: INITIAL_PAGED_DATA_STATE });
  }

  clearLinkPlantationDatasets() {
    this.setState({ plantationDatasets: undefined });
    this.setState({ transactionDatasets: undefined });
  }

  clearPlantationDatasets() {
    this.setState({ plantationDatasets: undefined });
  }

  clearTransactionDatasets() {
    this.setState({ transactionDatasets: undefined });
  }

  clearPlantationGeomatryData() {
    this.setState({ plantationGeometryData: undefined });
  }

  clearPlantationBySi() {
    this.setState({ plantationsBySi: undefined });
  }

  clearPlantationRisks() {
    this.setState({ plantationRisks: undefined });
  }

  setRiskLayers(riskLayers: RiskLayer[]) {
    this.setState({ riskLayers: riskLayers });
  }

  setCurrentScreen(screen: ScreenEnum) {
    this.setState({ currentScreen: screen });
  }

  setMapStyle(mapStyle: string) {
    this.setState({ mapStyle: mapStyle });
  }

  setSelectedTraceabilityData(data: TraceabilityData[]) {
    const selectedData = [...data.map((item) => ({ ...item, checked: true }))];
    this.setState({ selectedTraceabilityData: selectedData });
  }

  setCheckedSelectedTraceabilityData(data: TraceabilityData[]) {
    const selectedData = [
      ...data.map((item) => ({
        ...item,
      })),
    ];
    this.setState({ checkedSelectedTraceabilityData: selectedData });
  }

  setSelectedTab(selectedTab: TraceabilityTableTabsEnum) {
    this.setState({ selectedTab });
  }

  setTitle(title: string) {
    this.setState({ title });
  }

  resetTraceabilityState() {
    this.resetState(INITIAL_TRACEABILITY_STATE);
  }
}
