import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslocoPipe, TranslocoService } from '@jsverse/transloco';
import { TraceabilityStateService } from 'src/app/services/state-service/traceability-state.service';
import { CompanyTypeTableViewAsEnum } from '../../enums/traceability-table-tabs.enum';
import { TabViewModule } from 'primeng/tabview';
import { ScreenEnum, TraderLinkScreenEnum } from 'src/app/enums/screens.enum';
import { UserInfoStateService } from 'src/app/services/state-service/user-info-state.service';
import { EventStateService } from 'src/app/services/state-service/event-state.service';
import { distinctUntilChanged, skip, Subject, takeUntil } from 'rxjs';
import { TraderStateService } from 'src/app/services/state-service/trader-state.service';
import { CustomStepperComponent, CustomStepperMenuItem } from '../custom-stepper';
@Component({
  selector: 'app-page-header',
  standalone: true,
  imports: [
    CommonModule,
    TranslocoPipe,
    TabViewModule,
    CustomStepperComponent
  ],
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy {
  /**
   * Template variables
   */
  CompanyTypeTableViewAsEnum = CompanyTypeTableViewAsEnum;
  ScreenEnum = ScreenEnum;
  TraderLinkScreenEnum = TraderLinkScreenEnum;
  traderScreens: CustomStepperMenuItem[] = [
    {
      index: TraderLinkScreenEnum.SELECT_TRADE,
      label: this.translocoService.translate('DASHBOARD.TRADER_TRACEABILITY.STEPPER_MENU.SELECT_TRADE')
    },
    {
      index: TraderLinkScreenEnum.CONFIRMATION_DETAILS,
      label: this.translocoService.translate('DASHBOARD.TRADER_TRACEABILITY.STEPPER_MENU.CONFIRMATION_DETAILS'),
    },
    {
      index: TraderLinkScreenEnum.SUMMARY,
      label: this.translocoService.translate('DASHBOARD.TRADER_TRACEABILITY.STEPPER_MENU.SUMMARY'),
    }
  ];
    
  private destroyed$ = new Subject<void>();

  constructor(
    public traceabilityStateService: TraceabilityStateService,
    public traderStateService: TraderStateService,
    public userInfoStateService: UserInfoStateService,
    public eventStateService: EventStateService,
    private translocoService: TranslocoService
  ) {}

  ngOnInit(): void {
    this.eventStateService.isTraderLinkingTRN$
    .pipe(
      skip(1),
      distinctUntilChanged(),
      takeUntil(this.destroyed$)
    )
    .subscribe(() => {
      const buyers = this.traderStateService.buyer();
      this.onTabChange(buyers && buyers.length > 0 ? 1 : 0);
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  onTabChange(index: number) {
    this.traceabilityStateService.resetTraceabilityState();
    switch(index) {
      case 0: 
        this.userInfoStateService.setViewAs(CompanyTypeTableViewAsEnum.Buyer);
      break;
      case 1: 
        this.userInfoStateService.setViewAs(CompanyTypeTableViewAsEnum.Seller);
      break;
    }
  }

  onBackClicked() {
    this.traceabilityStateService.setCurrentScreen(ScreenEnum.DASHBOARD);
  }
}
