<ng-container>
  <div class="modal-container">
    <div *ngIf="isLoading" class="loading-overlay">
      <p-progressSpinner></p-progressSpinner>
    </div>
    <div>
      <div class="flex font-bold px-4 my-4">
        <div class="header-background font-semibold">
          <span *ngIf="!finalPage; else final">
            {{ translateSubheader("DASHBOARD.DOP.DOP_SUBHEADER") }}
          </span>
          <ng-template #final>
            <span>
              {{ translateSubheader("DASHBOARD.DOP.DOP_FINAL_SUBHEADER") }}
            </span>
          </ng-template>
        </div>
      </div>

      <ng-container>
        <div>
          <div class="flex px-4 flex-column">
            <div class="col-12">
              <p-card>
                <p-progressBar
                  class="progress-bar"
                  *ngIf="tableLoading"
                  mode="indeterminate"
                ></p-progressBar>
                <p-table
                  #dopTable
                  [value]="finalPage ? summaryPlantations : plantations"
                  [rows]="pageSize"
                  [columns]="columns"
                  [dataKey]="tableDataKey"
                  styleClass="p-datatable-sm"
                  [scrollable]="true"
                  [tableStyle]="{ 'max-width': '100%' }"
                  [paginator]="true"
                  [showCurrentPageReport]="true"
                  [loading]="tableLoading"
                  [selectionPageOnly]="true"
                  class="dop-table"
                  [globalFilterFields]="['plantation_name']"
                  currentPageReportTemplate="{{
                    'DASHBOARD.SHOWING_TABLE_ENTRIES_COUNT'
                      | transloco
                        : {
                            first: '{first}',
                            last: '{last}',
                            totalRecords: '{totalRecords}'
                          }
                  }}"
                  [rowsPerPageOptions]="[10, 30, 100, 1000]"
                  [(selection)]="selectedPlantations"
                  [(first)]="currentFirst"
                  (selectionChange)="onSelectionChange()"
                  (onPage)="onPageChange($event)"
                >
                  <ng-template pTemplate="caption">
                    <div class="grid p-2 mt-0" *ngIf="!finalPage">
                      <div
                        class="col-12 flex align-items-center justify-content-between"
                      >
                        <div class="flex table-header">
                          <span class="table-label mr-4">
                            {{
                              "DASHBOARD.DOP.LIST_OF_PLANTATIONS" | transloco
                            }}
                          </span>
                        </div>
                        <div>
                          <span class="p-input-icon-left">
                            <i class="pi pi-search"></i>
                            <input
                              type="text"
                              class="search-bar"
                              pInputText
                              (input)="search($event)"
                              placeholder="{{
                                'DASHBOARD.DOP.SEARCH_PLANTATIONS' | transloco
                              }}"
                            />
                          </span>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <ng-template pTemplate="header">
                    <tr *ngIf="!finalPage">
                      <th [attr.colspan]="columns.length + 1">
                        <p-checkbox
                          [binary]="true"
                          [(ngModel)]="selectAllChecked"
                          (onChange)="toggleSelectAll($event)"
                        ></p-checkbox>
                        <label class="ml-4">{{
                          "DASHBOARD.DOP.SELECT_ALL_PLANTATIONS" | transloco
                        }}</label>
                      </th>
                    </tr>
                    <tr>
                      <th style="width: 3em" *ngIf="!finalPage">
                        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                      </th>
                      <ng-container *ngFor="let column of columns; index as i">
                        <th
                          [style]="column?.style"
                          [pSortableColumn]="
                            column.sortable ? column.field : ''
                          "
                        >
                          <div class="th-container">
                            <label>
                              {{ column.displayName | transloco }}
                            </label>
                          </div>
                        </th>
                      </ng-container>
                    </tr>
                  </ng-template>

                  <!-- Table Body -->
                  <ng-template
                    pTemplate="body"
                    let-rowData
                    let-editing="editing"
                    let-index="rowIndex"
                  >
                    <tr *ngIf="!tableLoading">
                      <td *ngIf="!finalPage">
                        <p-tableCheckbox [value]="rowData"></p-tableCheckbox>
                      </td>
                      <ng-container *ngFor="let column of columns; index as i">
                        <td>
                          <ng-container
                            *ngIf="column.field !== 'production_dates'"
                          >
                            <label>{{ rowData[column.field] || "-" }}</label>
                          </ng-container>
                          <ng-container
                            *ngIf="column.field === 'production_dates'"
                          >
                            <ng-container>
                              <p-calendar
                                [(ngModel)]="rowData.production_dates"
                                selectionMode="range"
                                [showIcon]="true"
                                inputId="icondisplay"
                                class="calendar-range"
                                appendTo="body"
                                [disabled]="finalPage"
                                [maxDate]="today"
                                [dateFormat]="'dd/mm/yy'"
                              />
                            </ng-container>
                          </ng-container>
                        </td>
                      </ng-container>
                    </tr>
                  </ng-template>
                </p-table>
              </p-card>
            </div>
          </div>
          <div
            class="all-date-section flex flex-column pl-3 pt-3 m-3"
            *ngIf="!finalPage"
          >
            <span class="font-bold">{{
              "DASHBOARD.DOP.DOP_AUTOFILL" | transloco
            }}</span>
            <div>
              <p-calendar
                [(ngModel)]="allDateRange"
                selectionMode="range"
                [showIcon]="true"
                inputId="icondisplay"
                class="calendar-range"
                [disabled]="selectedPlantations.length === 0"
                [maxDate]="today"
                [dateFormat]="'dd/mm/yy'"
                appendTo="body"
              />
              <p-button
                label="{{ 'DASHBOARD.DOP.ADD_DOP' | transloco }}"
                class="pl-3"
                (onClick)="addDatesToAll()"
                [disabled]="selectedPlantations.length === 0"
              ></p-button>
              <!-- <div class="mt-5">
                <span class="font-bold">{{ 'DASHBOARD.DOP.EUDR_PACKAGE' | transloco }}</span>
              </div>
              <div [formGroup]="dopForm">
                <p-checkbox class="text-sm" [binary]="true"
                formControlName="includeInEudr"></p-checkbox>
                <span class="ml-2">{{ 'DASHBOARD.DOP.INCLUDE_IN_EUDR_PACKAGE' | transloco }}</span>
              </div> -->
            </div>
          </div>

          <div class="action-section p-3">
            <p-button
              label="{{ 'DASHBOARD.DOP.CANCEL' | transloco }}"
              [outlined]="true"
              (onClick)="closeModal()"
              *ngIf="!finalPage"
            ></p-button>
            <p-button
              label="{{ 'DASHBOARD.DOP.BACK' | transloco }}"
              [outlined]="true"
              (onClick)="back()"
              *ngIf="finalPage"
            ></p-button>
            <p-button
              class="ml-2"
              label="{{ 'DASHBOARD.DOP.NEXT' | transloco }}"
              (onClick)="nextPage()"
              *ngIf="!finalPage"
              [disabled]="!enableNext()"
            ></p-button>
            <p-button
              class="ml-2"
              label="{{ 'DASHBOARD.DOP.CONFIRM' | transloco }}"
              (onClick)="generateDop()"
              *ngIf="finalPage"
            ></p-button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
