<div class="flex mb-4" 
  *ngIf="(userInfoStateService.viewAsSetting$ | async) !== null && 
  !(eventStateService.isTraderLinkingTRN$ | async)">
  <p-tabView 
    class="traceability-trader-header-tabs"
    [activeIndex]="(userInfoStateService.viewAsSetting$ | async)?.view ?? 0"
    (activeIndexChange)="onTabChange($event)"
  >
    <p-tabPanel header="{{ 'DASHBOARD.TRADER_TRACEABILITY.TABS.CONSUMER' | transloco }}"></p-tabPanel>
    <p-tabPanel header="{{ 'DASHBOARD.TRADER_TRACEABILITY.TABS.PRODUCER' | transloco }}"></p-tabPanel>
  </p-tabView>
</div>

<ng-container *ngIf="!(eventStateService.isTraderLinkingTRN$ | async) else linkData" [ngSwitch]="(traceabilityStateService.currentScreen$) | async">
  <ng-container *ngSwitchCase="ScreenEnum.DASHBOARD">
    <div class="flex mb-3">
      <ng-container [ngSwitch]="(userInfoStateService.viewAsSetting$ | async)?.view">
        <span class="text-xl font-semibold">
          <ng-container *ngSwitchCase="CompanyTypeTableViewAsEnum.Buyer">{{ "DASHBOARD.TRADER_TRACEABILITY.HEADER.PRODUCER"
            | transloco }}</ng-container>
          <ng-container *ngSwitchCase="CompanyTypeTableViewAsEnum.Seller">{{ "DASHBOARD.TRADER_TRACEABILITY.HEADER.CONSUMER"
            | transloco }}</ng-container>
          <ng-container *ngSwitchDefault>{{ (traceabilityStateService.title$ | async) | transloco }}</ng-container>
        </span>
      </ng-container>
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="ScreenEnum.MAP_PAGE">
    <div class="flex">
      <span class="material-symbols-outlined text-4xl mr-2 back-button" (click)="onBackClicked()">
        arrow_back
      </span>
      <div class="mt-0_15">
        <span class="text-xl font-semibold">{{ (traceabilityStateService.title$ | async) | transloco }}</span>
      </div>
    </div>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <span class="text-xl font-semibold">{{ (traceabilityStateService.title$ | async) | transloco }}</span>
  </ng-container>
</ng-container>

<ng-template #linkData>
  <div class="text-xl font-semibold">
    <span>{{ 'DASHBOARD.TRADER_TRACEABILITY.LINK_DATA.PAGE_TITLE' | transloco }}</span>
  </div>
  <div class="mt-2 mb-4 font-semibold" [ngSwitch]="traderStateService.selectedScreen()">
    <span *ngSwitchCase="TraderLinkScreenEnum.SELECT_TRADE">{{ 'DASHBOARD.TRADER_TRACEABILITY.LINK_DATA.PAGE_DESCRIPTION_LIST.SELECT_TRADE' | transloco }}</span>    
    <span *ngSwitchCase="TraderLinkScreenEnum.CONFIRMATION_DETAILS">{{ 'DASHBOARD.TRADER_TRACEABILITY.LINK_DATA.PAGE_DESCRIPTION_LIST.CONFIRMATION_DETAILS' | transloco }}</span>
  </div>
  <app-custom-stepper [activeIndex]="traderStateService.selectedScreen() ?? 0" [menu]="traderScreens"></app-custom-stepper>
</ng-template>