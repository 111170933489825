import { Injectable } from '@angular/core';
import { StateService } from '../state-service/state.service';
import { INITIAL_USERINFO_STATE, UserInfoState } from 'src/app/models/user-info-state.model';
import { CompanyTypesEnum } from 'src/app/enums/company-types.enum';
import { CompanyTypeTableViewAsEnum } from 'src/app/enums/traceability-table-tabs.enum';
import { DashboardService } from '../data-service/dashboard.service';

@Injectable({
  providedIn: 'root'
})
export class UserInfoStateService extends StateService<UserInfoState> {

  viewAsSetting$ = this.select(state => state.viewAsSetting);
  userInfo$ = this.select(state => state.userInfo);
  userViewAs$ = this.select(state => state.userViewAs);
  isTrader$ = this.select(state => state.userInfo?.company?.type?.toLowerCase() === CompanyTypesEnum.Trader?.toLowerCase())

  constructor(private dashboardService: DashboardService) {
    super(INITIAL_USERINFO_STATE);
  }

  getUserInfo(defaultMode?: string) {
    this.dashboardService.getUserInfo().subscribe((res) => {
      this.setDefaultView(defaultMode, res.company.type);
      this.setState({ userInfo: res });
    });
  }

  private setDefaultView(defaultMode?: string, type?: string) {
    if (type?.toLowerCase() === CompanyTypesEnum.Trader?.toLowerCase()) {
      const selectedTab = defaultMode !== null && defaultMode !== undefined ? parseInt(defaultMode) as CompanyTypeTableViewAsEnum : CompanyTypeTableViewAsEnum.Buyer;
      this.setViewAs(selectedTab);
    } else {
      this.setViewAs(null, type);
    }
  }

  setViewAs(selectedTraderTab: CompanyTypeTableViewAsEnum | null, type?: string) {
    if (selectedTraderTab !== null) {
      const viewAs = selectedTraderTab === CompanyTypeTableViewAsEnum.Buyer ? CompanyTypesEnum.Consumer : CompanyTypesEnum.Producer;
      this.setState({
        userViewAs: viewAs,
        viewAsSetting: {
          view: selectedTraderTab,
          param: viewAs === CompanyTypesEnum.Producer ? { mode: 'seller' } : { mode: 'buyer' }
        }
      });
    } else {
      this.setState({ userViewAs: type as CompanyTypesEnum });
    }
  }
}
