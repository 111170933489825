import { CreateNewSiOptionsEnum } from "../enums/create-new-options.enum";
import { TraderLinkScreenEnum } from "../enums/screens.enum";
import { TraceabilityData } from "./traceability-state.model";

export interface TraderState {
  buyer: TraceabilityData[] | null;
  selectedScreen: TraderLinkScreenEnum | null;
  selectedCreateNewSiOption?: CreateNewSiOptionsEnum | null;
  createNewSiAction?: CreateNewSiAction
  selectedNewSi?: TraceabilityData | null
  showCreateSiModal?: boolean
  disableCreateSi?: boolean
}

export type CreateNewSiAction = 'next' | 'cancel' | 'submit'

export interface TraderScreenItem {
  label: string;
  disabled: boolean;
}

export const INITIAL_TRADER_STATE: TraderState = {
  buyer: null,
  selectedScreen: null
}
