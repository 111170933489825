import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PrimengExportsModule } from 'src/app/primeng-exports.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TranslocoPipe } from '@jsverse/transloco';
import { DashboardService } from 'src/app/services/data-service/dashboard.service';
import { catchError, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { SettingsStateService } from 'src/app/services/state-service/settings-state.service';

@Component({
  selector: 'app-email-to-counterparty',
  standalone: true,
  imports: [
    CommonModule,
    PrimengExportsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslocoPipe
  ],
  templateUrl: './email-to-counterparty.component.html',
  styleUrls: ['./email-to-counterparty.component.scss']
})
export class EmailToCounterpartyComponent {
  counterparty: string = '';
  siNumber: string = '';
  siDetailId: number = 0;
  counterpartyEmails: string[] = [];
  destroyed$ = new Subject<void>();
  isLoading = true;
  filesLoading = true;

  filesInEudr = [];
  filesToSend: string[] = [];

  constructor(
    public dialogConfig: DynamicDialogConfig,
    private dashboardService: DashboardService,
    private settingsStateService: SettingsStateService,
    private dialogRef: DynamicDialogRef
  ) {}

  ngOnInit() {
    this.siNumber = this.dialogConfig.data?.rowData?.si_number;
    this.siDetailId = this.dialogConfig.data?.rowData?.sidetail_id;
    this.counterparty = this.dialogConfig.data?.rowData?.counterparty === 'HG' ? 'HEVEA GLOBAL' : this.dialogConfig.data?.rowData?.counterparty;
    this.getCounterpartyEmails();
    this.getEudrFiles();
  }

  getEudrFiles(){
    this.settingsStateService.settings$
      .pipe(
        takeUntil(this.destroyed$),
        switchMap((settings: any) => 
          this.dashboardService.getEudrFiles(this.siNumber, this.siDetailId).pipe(
            tap((files: any) => {
              if(files.length > 0 && settings?.include_eudr_package_zip_in_email) {
                this.filesInEudr = files.map((file: any) => file.filename);
                this.filesToSend = [...this.filesInEudr];
              }
              this.filesLoading = false;
            })
          )
        ),
        catchError((error) => {
          console.error('Error fetching EUDR files:', error);
          this.filesLoading = false;
          return [];
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  get formattedCounterpartyEmails(): string {
    return this.counterpartyEmails.join('; ');
  }

  getCounterpartyEmails() {
    this.dashboardService.getCounterpartyEmails(this.counterparty).pipe(
      takeUntil(this.destroyed$)
    )
    .subscribe({
      next: (res) => {
        if (res) {
          this.counterpartyEmails = res.si_email_recipients;
        } 
        this.isLoading = false;
      },
      error: () => {
        this.closeModal()
      }
    });
  } 

  sendEmailToCounterparty() {
    this.settingsStateService.settings$
      .pipe(
        takeUntil(this.destroyed$),
        tap((settings: any) => {
          const includeToEudrPackage = settings?.include_eudr_package_zip_in_email && this.filesToSend.length > 0;
          const siList = [this.dialogConfig.data?.rowData?.si_number];
          const siDetailList = [this.dialogConfig.data?.rowData?.sidetail_id];
          
          const eudrFiles = (this.filesToSend.length !== this.filesInEudr.length) ? this.filesToSend : undefined;

          this.dashboardService.emailToCounterParty(siList, siDetailList, this.counterpartyEmails, includeToEudrPackage, eudrFiles);
          this.dialogRef.close();
        })
      )
      .subscribe();
  }

  closeModal() {
    this.dialogRef.close();
  }
}
