<div
  *ngIf="
    !(eventStateService.isCreatedNewSiLoading$ | async);
    else createNewSiLoading
  "
  class="flex flex-column"
>
  <div class="flex my-4 px-4">
    <span>{{ subHeader | transloco }}</span>
  </div>
  <div class="grid modal-table px-4 mb-5">
    <div class="col-12">
      <ng-container *ngIf="!isFinalPage; else finalPage">
        <ng-container
          *ngIf="
            (traceabilityStateService.pagedTradeConfirmationData$ | async) ===
              INITIAL_PAGED_DATA_STATE &&
            (traceabilityStateService.pagedCounterpartiesData$ | async) ===
              INITIAL_PAGED_DATA_STATE
          "
        >
          <ng-template *ngTemplateOutlet="tableLoading"></ng-template>
        </ng-container>
        <p-table
          [class.hidden]="
            (traceabilityStateService.pagedTradeConfirmationData$ | async) ===
              INITIAL_PAGED_DATA_STATE &&
            (traceabilityStateService.pagedCounterpartiesData$ | async) ===
              INITIAL_PAGED_DATA_STATE
          "
          #dt
          [value]="rowData"
          [paginator]="true"
          [rows]="pageRows"
          [selection]="selectedItem"
          (selectionChange)="onSelectionChange($event)"
          (onLazyLoad)="loadTable($event)"
          [lazy]="true"
          [loading]="(isTableDataLoading$ | async)!"
          [totalRecords]="totalRecords"
          [columns]="columns"
          dataKey="id"
          [tableStyle]="{ 'min-width': '64rem', height: '' }"
          selectionMode="single"
          [showCurrentPageReport]="true"
          [loading]="(isTableDataLoading$ | async)!"
          currentPageReportTemplate="{{
            'DASHBOARD.SHOWING_TABLE_ENTRIES_COUNT'
              | transloco
                : {
                    first: '{first}',
                    last: '{last}',
                    totalRecords: '{totalRecords}'
                  }
          }}"
          [globalFilterFields]="globalFilterFields"
          [rowsPerPageOptions]="[10, 30, 100]"
        >
          <ng-template pTemplate="caption">
            <div class="grid p-2 mt-0" *ngIf="selectedCreateNewSiOption === createNewSiOptions.TRADE_CONFIRMATION">
              <div
                class="col-12 flex align-items-center justify-content-between"
              >
                <div class="flex table-header">
                  <span class="table-label mr-4">
                    {{ tableHeader | transloco }}
                  </span>
                </div>
                <div>
                  <span class="p-input-icon-left">
                    <i class="pi pi-search"></i>
                    <input
                      type="text"
                      (input)="applyFilterGlobal($event)"
                      class="search-bar"
                      pInputText
                      placeholder="{{
                        'DASHBOARD.CREATE_NEW_SI.SEARCH' | transloco
                      }}"
                    />
                  </span>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template pTemplate="header">
            <tr>
              <ng-container *ngFor="let column of columns; index as i">
                <th
                  [style]="column?.style"
                  [pSortableColumn]="column.sortable ? column.field : ''"
                >
                  <div class="th-container">
                    <label>
                      {{ column.displayName | transloco }}
                    </label>
                  </div>
                </th>
              </ng-container>
            </tr>
          </ng-template>
          <ng-template
            pTemplate="body"
            let-rowData
            let-editing="editing"
            let-index="rowIndex"
          >
            <tr [pSelectableRow]="rowData">
              <ng-container *ngFor="let column of columns; index as i">
                <td>
                  <ng-container *ngIf="!column?.editable">
                    <label>{{ rowData[column.field] || "-" }}</label>
                  </ng-container>
                </td>
              </ng-container>
            </tr>
          </ng-template>
        </p-table></ng-container
      >
    </div>
  </div>
  <div class="flex justify-content-end modal-action-section" *ngIf="!(eventStateService.isTraderLinkingTRN$ | async)">
    <p-button
      *ngIf="isFinalPage"
      class="mr-4 mt-4"
      [outlined]="true"
      (onClick)="onBackClicked()"
      >{{ "DASHBOARD.CREATE_NEW_SI.BACK" | transloco }}</p-button
    >
    <p-button
      class="mr-4 mt-4"
      (onClick)="onPrimaryActionClicked()"
      [disabled]="isPrimaryActionDisabled"
      >{{ primaryActionText | transloco }}</p-button
    >
  </div>
</div>

<ng-template #tableLoading>
  <p-skeleton width="100%" height="45vh"></p-skeleton>
</ng-template>

<ng-template #finalPage>
  <div style="width: 100%">
    <app-creat-new-si-form
      (formValidity)="onFormValidityChanged($event)"
      (formValues)="onFormValuesChanged($event)"
      [isTradeConfirmation]="
        selectedCreateNewSiOption === createNewSiOptions.TRADE_CONFIRMATION
      "
      [selectedItem]="selectedItem"
    ></app-creat-new-si-form>
  </div>
</ng-template>

<ng-template #createNewSiLoading>
  <div
    class="flex justify-content-center align-items-center"
    style="height: 35rem"
  >
    <p-progressSpinner></p-progressSpinner></div
></ng-template>
