<nav *ngIf="menu && menu.length > 0">
  <ul class="custom-stepper-menu">
    <ng-container *ngFor="let item of menu; let i = index">
      <li class="custom-stepper-menu__item" [ngClass]="{ 'active': activeIndex ===  i, 'completed': activeIndex > i }">
        <a role="link" class="link-content">
          <small class="link-content__number">{{ i + 1 }}</small>
          <span class="link-content__title">{{ item.label }}</span>
        </a>
        <div class="link-separator" *ngIf="menu.length !== i + 1">
          <span class="link-separator"></span>
        </div>
      </li>
    </ng-container>
  </ul>
</nav>